import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Certificate } from 'src/app/shared/models/tables/certificate.model';
import { UserCourse } from 'src/app/shared/models/tables/userCourse.model';
import { CertificatesListConfig } from 'src/app/shared/queryConfigs/certificates-list-config.model';
import { CoursesListConfig } from 'src/app/shared/queryConfigs/courses-list-config.model';
import { CertificatesService } from 'src/app/shared/services/api/certificates.service';
import { CoursesService } from 'src/app/shared/services/api/courses.service';
import { TimingService } from 'src/app/shared/services/api/timing.service';
import { JWTService } from 'src/app/shared/services/jwt.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  static MAX_LENGTH = 100;
  private dbReadySubject = new ReplaySubject<boolean>(1);
  dbReady$: Observable<boolean> = this.dbReadySubject.asObservable();
  private updateAvatarSubject = new BehaviorSubject<string>(null);
  updateAvatar$: Observable<string> = this.updateAvatarSubject.asObservable();
  private updateFontSizeSubject = new BehaviorSubject<string>(null);
  updateFontSize$: Observable<string> = this.updateFontSizeSubject.asObservable();
  private updateLastSyncSubject = new BehaviorSubject<number>(null);
  updateLastSync: Observable<number> = this.updateLastSyncSubject.asObservable();
  private updateTimingInterval = null;
  private alertSync: BehaviorSubject<boolean>;
  private identity = null;
  private database = null;
  private userId = null;
  private dbRoot = '';
  private dbTimingRoot = '';
  private dbAvatar = '';
  private encryptSecretKey = '76z(7.J8E13=4dXm.+H$97pQrQ{hTsPI8R:Z:pE]';
  private encrypt = environment.production;
  private syncId: number = null;


  constructor(
    private plt: Platform,
    private http: HttpClient,
    private jwtService: JWTService,
    private storage: Storage,
    private coursesService: CoursesService,
    private certificateService: CertificatesService,
    private toastService: ToastService,
    private timingService: TimingService
  ) {
    this.alertSync = new BehaviorSubject<boolean>(false);
    this.plt.ready().then(() => {
      this.storage.create().then(() => {
        this.jwtService.identity$.subscribe(identity => {
          this.identity = identity;
          this.initializeDb().then(_ => {
            if (identity) {
              this.syncTiming().then(() => {
                this.updateTiming();
              });
            }
          });
        });
      });
    });
  }

  get alertSyncStatus(): Observable<boolean> {
    return this.alertSync.asObservable();
  }

  setCourse(userCourse: UserCourse): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.database && userCourse.userId === this.userId) {
        this.database.courses[userCourse.courseId] = userCourse;
        this.save();
        resolve(true);
      } else {
        const error = new Error('error.dbNotReady');
        reject(error);
      }
    });
  }

  setCertificate(certificate: Certificate): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.database && certificate.userId === this.userId) {
        this.database.certificates[certificate.id] = certificate;
        this.save();
        resolve(true);
      } else {
        const error = new Error('error.dbNotReady');
        reject(error);
      }
    });
  }

  getAvatar(): Promise<string> {
    return this.storage.get(this.dbAvatar);
  }

  setAvatar(avatar?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (avatar == null) {
        this.storage.remove(this.dbAvatar).then(_ => {
          resolve(true);
        }).catch(e => {
          this.toastService.presentToast(e.message, null, 'danger');
        });
      } else {
        this.storage.set(this.dbAvatar, avatar).then(_ => {
          resolve(true);
        }).catch(e => {
          this.toastService.presentToast(e.message, null, 'danger');
        });
      }
      this.updateAvatarSubject.next(avatar);
      resolve(true);
    });
  }

  getCourseIcon(md5: string): Promise<string> {
    return this.storage.get('course_' + md5);
  }

  saveCourseIcon(md5: string, icon: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storage.set('course_' + md5, icon).then(_ => {
        resolve(true);
      }).catch(e => {
        this.toastService.presentToast(e.message, null, 'danger');
      });
      resolve(true);
    });
  }

  getFontSize() {
    if (this.database) {
      return this.database.fontSize;
    }
  }

  setFontSize(fontSize: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.database) {
        this.database.fontSize = fontSize;
        this.save();
        this.updateFontSizeSubject.next(fontSize);
        resolve(true);
      } else {
        const error = new Error('error.dbNotReady');
        reject(error);
      }
    });
  }

  getTimeLeft(chapterId: number) {
    let timeLeft = null;
    if (this.database && this.database.timing?.hasOwnProperty(chapterId)) {
      timeLeft = this.database.timing[chapterId];
    }
    return timeLeft;
  }

  setTimeLeft(chapterId: number, value: number, sync = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.database) {
        this.database.timing[chapterId] = value;
        if (sync) {
          this.database.doSync = true;
        }
        // salvo ogni 10 secondi
        if (sync || value % 10 === 0) {
          this.storage.set(this.dbTimingRoot, this.encryptData(this.database.timing)).then(_ => {
            resolve(true);
          }).catch(e => {
            this.toastService.presentToast(e.message, null, 'danger');
          });
        }
      } else {
        const error = new Error('error.dbNotReady');
        reject(error);
      }
    });
  }

  removeTimes(chapterIds: number[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.database) {
        for (const chapterId of chapterIds) {
          delete this.database.timing[chapterId];
        }
        this.storage.set(this.dbTimingRoot, this.encryptData(this.database.timing)).then(_ => {
          resolve(true);
        }).catch(e => {
          this.toastService.presentToast(e.message, null, 'danger');
        });
      } else {
        const error = new Error('error.dbNotReady');
        reject(error);
      }
    });
  }

  getCourses() {
    const courses = [];
    if (this.database) {
      for (const course of Object.values(this.database.courses) as UserCourse[]) {
        if (course.Course) {
          let timeLeft = 0;
          const totalTime = +course.Course.time;
          if (course.Course.Chapters) {
            for (const c of course.Course.Chapters) {
              timeLeft += this.getTimeLeft(c.id) / 60;
            }
          }
          let percent = 1;
          if (totalTime > 0) {
            percent = timeLeft / totalTime;
            if (percent > 1) {
              percent = 1;
            }
          } else if (totalTime === 0) {
            percent = 1;
          }
          const fileIcon = this.getFileByType(course.Course, 'icon');
          courses.push({id: course.courseId, name: course.Course.name, percent, fileIcon});
        }
      }
    }
    return courses;
  }

  getCertificates() {
    const certificates: Certificate[] = [];
    if (this.database && this.database.certificates) {
      for (const certificate of Object.values(this.database.certificates) as Certificate[]) {
        certificate.validity = certificate.validity.replace(' ', 'T');
        certificates.push({...certificate, percent: this.setPercent(certificate.validity)});
      }
    }
    certificates.sort((a, b) => (a.date > b.date) ? -1 : 1);
    return certificates;
  }

  getCourse(courseId) {
    if (courseId in this.database.courses) {
      return this.database.courses[courseId];
    } else {
      return null;
    }
  }

  initializeDb(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.identity !== null) {
        this.userId = this.identity.user.id;
        const md5 = new Md5();
        const id = md5.appendStr('TrainingdbOfUser' + this.userId).end();
        this.dbRoot = 'tr_' + id + '.db';
        this.dbTimingRoot = 'tm_' + id + '.db';
        this.dbAvatar = 'av_' + id + '.db';
        this.read().finally(() => {
          resolve(true);
        });
      } else {
        this.cleanDb();
        this.userId = null;
        this.dbRoot = '';
        this.dbTimingRoot = '';
        this.dbAvatar = '';
        this.dbReadySubject.next(false);
        // this.storage.clear();
        resolve(true);
      }
    });
  }

  sync(): Promise<boolean> {
    this.syncId = Date.now();
    return new Promise((resolve, reject) => {
      this.syncCourses(1).then(() => {
        // pulisco quelli eliminati
        Object.getOwnPropertyNames(this.database.courses).forEach(p => {
          if (this.database.courses[p].syncId !== this.syncId) {
            delete this.database.courses[p];
          }
        });
        this.syncCertificates(1).then(() => {
          // pulisco quelli eliminati
          Object.getOwnPropertyNames(this.database.certificates).forEach(p => {
            if (this.database.certificates[p].syncId !== this.syncId) {
              delete this.database.certificates[p];
            }
          });
          this.syncTiming().then(() => {
            this.syncId = null;
            this.save().finally(() => {
              resolve(true);
            });
          }).catch(error => {
            reject(error);
          });
        }).catch(error => {
          reject(error);
        });
      }).catch(error => {
        reject(error);
      });
    });
  }

  clean() {
    return new Promise((resolve) => {
      if (this.database.lock) {
        resolve(false);
      } else {
        this.cleanDb();
        this.save().then(() => {
          this.dbReadySubject.next(false);
          resolve(true);
        });
      }
    });
  }

  getFileByType(obj, type) {
    let file = null;
    if (obj.files != null) {
      file = obj.files.find(f => f.type === type && f.mime.includes('image'));
    }
    return file;
  }

  public syncTiming(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.timingService.query().subscribe(res => {
        if (res.success && this.database && (this.database.lastSync == null || res.lastSync > this.database.lastSync)) {
          if (res.timing) {
            const newTiming = this.decryptData(res.timing, true);
            if (typeof newTiming !== 'object') {
              this.alertSync.next(true);
            } else {
              this.storage.set(this.dbTimingRoot, this.encryptData(newTiming)).then(_ => {
              }).catch(e => {
                this.toastService.presentToast(e.message, null, 'danger');
              });
              this.alertSync.next(false);
              this.database.timing = newTiming;
              this.database.lastSync = res.lastSync;
              this.updateLastSyncSubject.next(res.lastSync);
            }
          } else {
            this.alertSync.next(false);
          }
        }
        resolve(true);
      }, error => {
        this.alertSync.next(true);
        reject(error);
      });
    });
  }

  getLastSync() {
    if (this.database) {
      return this.database.lastSync;
    }
  }

  private cleanDb() {
    if (this.database == null) {
      this.database = {};
    }
    this.database.courses = {};
    this.database.certificates = {};
    this.database.fontSize = 'M';
    this.database.timing = {};
    this.database.lastSync = null;
    this.database.doSync = false;
  }

  private setPercent(val): number {
    const warningDate = new Date();
    let percent = 0;
    const validity = new Date(val);
    const oneDay = 24 * 60 * 60 * 1000;
    if (validity > warningDate) {
      warningDate.setMonth(warningDate.getMonth() + 3);
      if (validity > warningDate) {
        percent = 1;
      } else {
        const days = Math.round(Math.abs((warningDate.getTime() - validity.getTime()) / oneDay));
        percent = 1 - days / 90;
      }
    }
    return percent;
  }

  private syncCertificates(page): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const limit = 1;
      const query: CertificatesListConfig = new CertificatesListConfig();
      query.limits.limit = limit;
      query.limits.offset = (limit * (page - 1));
      this.certificateService.query(query).subscribe(data => {
        const rows = page * limit;
        if (data.count > 0) {
          for (const certificate of data.certificates) {
            certificate.syncId = this.syncId;
            try {
              this.setCertificate(certificate).catch((error) => {
                reject(error);
              });
            } catch (error) {
              reject(error);
            }
          }
          if (data.count > rows) {
            this.syncCertificates(page + 1).then(result => {
              resolve(result);
            }).catch(error => {
              reject(error);
            });
          } else {
            // finito
            resolve(true);
          }
        } else {
          resolve(true);
        }
      }, error => {
        reject(error);
      });
    });
  }

  private syncCourses(page): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const limit = 1;
      const query: CoursesListConfig = new CoursesListConfig();
      query.limits.limit = limit;
      query.limits.offset = (limit * (page - 1));
      this.coursesService.query(query).subscribe(data => {
        const rows = page * limit;
        if (data.count > 0) {
          for (const userCourse of data.userCourses) {
            userCourse.syncId = this.syncId;
            try {
              this.setCourse(userCourse).catch((error) => {
                reject(error);
              });
            } catch (error) {
              reject(error);
            }
          }
          if (data.count > rows) {
            this.syncCourses(page + 1).then(result => {
              resolve(result);
            }).catch(error => {
              reject(error);
            });
          } else {
            // finito
            resolve(true);
          }
        } else {
          resolve(true);
        }
      }, error => {
        reject(error);
      });
    });
  }

  private save(): Promise<any> {
    const db = {...this.database};
    db.courses = this.encryptData(db.courses);
    db.certificates = this.encryptData(db.certificates);
    if (db.timing) {
      delete db.timing;
    }
    return this.storage.set(this.dbRoot, db);
  }

  private read(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storage.get(this.dbRoot).then(db => {
        if (db) {
          db.courses = this.decryptData(db.courses);
          if (db.timing) {// retrocompatibilità
            db.timing = this.decryptData(db.timing);
          }
          db.certificates = this.decryptData(db.certificates);
          this.database = db;
          if (typeof db.courses === 'string' || db.courses instanceof String) { // possibile cambio di stato di encrypt: reinizializzo
            this.toastService.presentToast('db.unreadible');
            this.cleanDb();
          }
        } else {
          this.cleanDb();
        }
        this.storage.get(this.dbTimingRoot).then(dbT => {
          if (dbT) {
            this.database.timing = this.decryptData(dbT);
          } else {
            if (this.database.timing == null) {
              this.database.timing = {};
            }
          }
          this.dbReadySubject.next(true);
          resolve(true);
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  private getKey(newVersion = true): string {
    const md5 = new Md5();
    const key =
      newVersion ?
        md5.appendStr(this.userId.toString()).end().toString() + this.encryptSecretKey :
        md5.appendStr(this.userId).end().toString() + this.encryptSecretKey;
    return key;
  }

  private encryptData(data, force = false) {
    if (!this.encrypt && !force) {
      return data;
    }
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.getKey()).toString();
    } catch (e) {
      throw e;
    }
  }

  private decryptData(data, force = false) {
    if (!this.encrypt && !force) {
      return data;
    }
    try {
      let bytes = CryptoJS.AES.decrypt(data, this.getKey());
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } else {
        bytes = CryptoJS.AES.decrypt(data, this.getKey(false));
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      }
      return data;
    } catch (e) {
      try {
        const bytes = CryptoJS.AES.decrypt(data, this.getKey(false));
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        return data;
      } catch (e) {
        throw e;
      }
    }
  }

  private updateTiming() {
    clearInterval(this.updateTimingInterval);
    this.updateTimingInterval = setTimeout(() => {
      this.updateTiming();
    }, environment.syncTimingTimeInterval);
    if (this.database.doSync) {
      this.timingService.save({timing: this.encryptData(this.database.timing, true)}).subscribe(
        (result) => {
          this.alertSync.next(!result.success);
          if (result.success) {
            this.database.doSync = false;
            this.database.lastSync = result.lastSync;
            this.updateLastSyncSubject.next(result.lastSync);
          }
          this.save();
        },
        error => {
          this.alertSync.next(true);
        });
    }

  }
}









