export class ListConfig {
  filters: {} = {};

  limits: {
    limit?: number,
    offset?: number
  } = {};

  sorting: {
    order?: string;
    sort?: string;
  } = {};

  include: {} = {};
}
