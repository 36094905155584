import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppFile } from '../../models/app-file.model';
import { ApiService } from '../api.service';


@Injectable()
export class AppFilesService {

  constructor(
    private apiService: ApiService
  ) {
  }

  download(file: AppFile): Observable<any> {
    return this.apiService.download(file.path + '/1');
  }

  view(file: AppFile): Observable<any> {
    return this.apiService.download(file.path);
  }

  play(file: AppFile): Observable<{ success: boolean, video: string }> {
    return this.apiService.get(file.path, null, false);
  }

  delete(uri): Observable<any> {
    return this.apiService.delete(uri, false);
  }

  upload(file: File, model: string, modelId: string):
    Observable<{ success: boolean, progress: number } | { success: boolean, file?: AppFile, error?: { message: string } }> {
    return this.apiService.upload(
      '/files/user',
      {
        file
      },
      {
        model,
        modelId
      }
    ).pipe(
      map(data => {
        if (data) {
          // @ts-ignore
          if (data.file) {
            // @ts-ignore
            data.file = new AppFile(data.file);
          }
          // @ts-ignore
          if (data.success === false && data.errors) {
            // @ts-ignore
            throw(data.errors);
          }
        }
        return data;
      })
    );
  }


}
