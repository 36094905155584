import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgOtpInputModule } from 'ng-otp-input';
import * as Sentry from 'sentry-cordova';
import { NotEmptyTranslationLoader } from 'src/app/not-empty-translator';
import { PageNotFoundComponent } from 'src/app/shared/errors/page-not-found/page-not-found.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UiModule } from 'src/app/ui/ui.module';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

Sentry.init({dsn: 'https://b9d181c9df500e88b57d2758deb6acc4@o328724.ingest.sentry.io/4506001835687936'});


export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      if (environment.production) {
        Sentry.captureException(error.originalError || error);
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function TranslationLoaderFactory(http: HttpClient) {
  return new NotEmptyTranslationLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  entryComponents: [],
  imports: [
    AngularFileViewerModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgOtpInputModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: '__training',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -10,
      outerStrokeGradient: true,
      outerStrokeWidth: 10,
      outerStrokeColor: '#4882c2',
      outerStrokeGradientStopColor: '#53a9ff',
      innerStrokeColor: '#e7e8ea',
      innerStrokeWidth: 10,
      title: 'UI',
      animateTitle: false,
      animationDuration: 1000,
      showUnits: true,
      showBackground: false,
      startFromZero: false,
    }),
    SharedModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient]}
    }),
    UiModule
  ],
  providers: [
    AppVersion,
    Device,
    Insomnia,
    File,
    FileOpener,
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: ErrorHandler, useClass: SentryIonicErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
