import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';

import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { JWTService } from 'src/app/shared/services/jwt.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private jwtService: JWTService,
    private navController: NavController
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.jwtService.token$.pipe(
      take(1),
      switchMap(token => {
        if (token == null) {
          this.navController.navigateRoot(['/auth', 'login']);
          return of(false);
        } else {
          if (route.data.permissions && Object.values(route.data.permissions).length > 0) {
            return forkJoin(
              Object.keys(route.data.permissions).filter(permissionKey => permissionKey.indexOf('can') === 0).map(permissionKey => {
                let permissionChecks: Array<string> = route.data.permissions[permissionKey];
                if (!Array.isArray(permissionChecks)) {
                  permissionChecks = [permissionChecks];
                }
                return {key: permissionKey, checks: permissionChecks};
              }).map(permission => {
                return combineLatest(permission.checks.map(permissionCheck => {
                  return this.jwtService.checkPermission(permissionCheck);
                })).pipe(
                  take(1),
                  map(permissionValues => {
                    let permissionKey = permission.key.replace('can', '');
                    permissionKey = permissionKey[0].toLowerCase() + permissionKey.slice(1);
                    return {key: permissionKey, value: permissionValues.every(permissionValue => permissionValue === true)};
                  })
                );
              })
            ).pipe(
              tap(permissions => permissions.forEach(permission => route.data.permissions[permission.key] = permission.value)),
              switchMap(() => this.jwtService.identity$),
              map(() => route.data.permissions.activate)
            );
          } else {
            return of(true);
          }
        }
      })
    );
  }
}
