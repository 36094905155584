import { EventEmitter, Injectable, Output } from '@angular/core';
import { Storage } from '@ionic/storage';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Identity } from 'src/app/shared/models/identity.model';
import { JWT } from 'src/app/shared/models/jwt.model';

const STREBA_TOKENS = 'training-tokens';

@Injectable({
  providedIn: 'root'
})
export class JWTService {
  private status = false;
  @Output() ready: EventEmitter<boolean> = new EventEmitter();

  private tokenSubject = new ReplaySubject<string>(1);
  token$: Observable<string> = this.tokenSubject.asObservable();

  private identitySubject = new ReplaySubject<Identity>(1);
  identity$: Observable<Identity> = this.identitySubject.asObservable();

  private refreshTokenSubject = new ReplaySubject<string>(1);
  refreshToken$: Observable<string> = this.refreshTokenSubject.asObservable();

  private tokenRefreshingSubject = new BehaviorSubject<boolean>(false);
  tokenRefreshing$: Observable<boolean> = this.tokenRefreshingSubject.asObservable();


  constructor(
    private storage: Storage
  ) {
    this.token$.subscribe(token => {
      this.identitySubject.next(token ? jwt_decode(token) : null);
    });
    this.storage.create().then(() => {
      this.storage.get(STREBA_TOKENS).then(tokens => {
        const jwt = JSON.parse(tokens);
        if (jwt) {
          this.tokenSubject.next(jwt.token);
          this.refreshTokenSubject.next(jwt.refreshToken);
        } else {
          this.tokenSubject.next(null);
          this.refreshTokenSubject.next(null);
        }
        this.status = true;
        this.ready.emit(true);
      });
    });
  }

  getStatus() {
    return this.status;
  }

  updateTokens(jwt: JWT): boolean {
    this.storage.set(STREBA_TOKENS, JSON.stringify(jwt));
    const token = jwt.token;
    if (token) {
      this.tokenSubject.next(jwt.token);
      this.refreshTokenSubject.next(jwt.refreshToken);
      return true;
    } else {
      return false;
    }
  }

  removeTokens(): Observable<boolean> {
    this.storage.remove(STREBA_TOKENS);
    this.tokenSubject.next(null);
    this.refreshTokenSubject.next(null);
    return of(true);
  }

  startTokenRefreshing() {
    this.tokenRefreshingSubject.next(true);
  }

  endTokenRefreshing() {
    this.tokenRefreshingSubject.next(false);
  }

  checkPermission(checkPermissions: Array<string> | string): Observable<boolean> {
    if (typeof checkPermissions === 'string') {
      checkPermissions = [checkPermissions];
    }
    return this.identity$.pipe(
      map(identity => {
        if (identity) {
          const permissions = identity.permissions;
          for (const checkPermission of checkPermissions) {
            if (permissions.indexOf(checkPermission) === -1) {
              return false;
            }
          }

          return true;
        } else {
          return false;
        }
      })
    );
  }
}
