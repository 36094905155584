import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppFile } from 'src/app/shared/models/app-file.model';
import { Identity } from 'src/app/shared/models/identity.model';
import { AppFilesService } from 'src/app/shared/services/api/app-files.service';
import { CoursesService } from 'src/app/shared/services/api/courses.service';
import { DbService } from 'src/app/shared/services/db.service';
import { JWTService } from 'src/app/shared/services/jwt.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  selectedIndex = '';
  identity: Identity;
  isLoggedIn: boolean;
  public isSync = false;
  public logo = '';
  public userImg = null;
  public showMenu = true;
  public VersionCode: string | number;
  public showFooter = false;
  public lastSync: Date = null;
  private currentLang = '';
  private currentPage = 0;
  public offset = 1;

  constructor(
    private jwtService: JWTService,
    private router: Router,
    private alertController: AlertController,
    private coursesService: CoursesService,
    private insomnia: Insomnia,
    private dbService: DbService,
    private toastService: ToastService,
    private menuService: MenuService,
    private navController: NavController,
    public translateService: TranslateService,
    private appFilesService: AppFilesService
  ) {
  }

  ngOnInit() {

    this.VersionCode = environment.version;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedIndex = window.location.pathname;
      }
    });

    this.jwtService.identity$.subscribe(identity => {
      this.identity = identity;
      this.isLoggedIn = identity != null;
      if (!this.isLoggedIn) {
        this.lastSync = null;
      }
    });

    this.menuService.menu.subscribe(show => {
      this.showMenu = show;
    });

    this.menuService.sync.subscribe((v) => {
      if (v > 0) {
        this.startSync();
      }
    });

    this.translateService.onLangChange.subscribe(() => {
      this.currentLang = this.translateService.currentLang;
    });
    this.logo = environment.logoPath;

    this.dbService.updateAvatar$.subscribe(avatar => {
      this.userImg = avatar;
    });

    const alertSyncStatus = this.dbService.alertSyncStatus;
    alertSyncStatus.subscribe(val => {
      this.showFooter = val;
      if (val) {
        setTimeout(() => {
          this.showFooter = false;
        }, 10000);
      }
    });

    this.dbService.updateAvatar$.subscribe(avatar => {
      this.userImg = avatar;
    });

    this.dbService.updateLastSync.subscribe(time => {
      if (time != null) {
        this.lastSync = new Date(time);
        // FIX :-(
        this.offset = Math.abs(this.lastSync.getTimezoneOffset()) > 60 ? 2 : 1;
      }
    });

    this.dbService.dbReady$.subscribe((ready) => {
      if (ready) {
        const ls = this.dbService.getLastSync();
        if (ls != null) {
          this.lastSync = new Date(ls);
          this.offset = Math.abs(this.lastSync.getTimezoneOffset()) > 60 ? 2 : 1;
        }

        this.dbService.getAvatar().then(avatar => {
          if (avatar == null) {
            if (this.identity?.user?.files?.length > 0) {
              const file = this.identity.user.files[0];
              this.setNewAvatar(file);
            }
          } else {
            this.userImg = avatar;
          }
        });
      }
    });
  }

  setNewAvatar(file: AppFile) {
    this.appFilesService.view(file).subscribe(b => {
      const blob = new Blob([b], {type: file.mime});
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        this.userImg = base64data.toString();
        this.dbService.setAvatar(this.userImg);
      };
    });
  }


  public async presentAlertLanguages() {
    const inputsList = [];
    for (const lang in environment.languages) {
      if (environment.languages.hasOwnProperty(lang)) {
        inputsList.push({
          name: environment.languages[lang].code,
          type: 'radio',
          label: environment.languages[lang].label,
          value: lang,
          class: 'dark',
          checked: this.translateService.currentLang === environment.languages[lang].code
        });
      }
    }
    const alert = await this.alertController.create({
      header: this.translateService.instant('selectLanguage'),
      inputs: inputsList,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            this.translateService.use(environment.languages[data].code);
          },
          cssClass: 'warning',
        }
      ]
    });
    await alert.present();
  }

  public async presentAlertReset() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('reset.alert'),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.dbService.clean().then(() => {
              this.navController.navigateRoot('home');
            });
          },
          cssClass: 'warning',
        }
      ]
    });
    await alert.present();
  }

  public async presentAlertFontSizes() {
    const currentFontSize = this.dbService.getFontSize();
    const inputsList = [];
    inputsList.push({
      name: 'small',
      type: 'radio',
      label: this.translateService.instant('font.small'),
      value: 'S',
      class: 'dark',
      checked: currentFontSize === 'S'
    });
    inputsList.push({
      name: 'medium',
      type: 'radio',
      label: this.translateService.instant('font.medium'),
      value: 'M',
      class: 'dark',
      checked: currentFontSize === 'M' || currentFontSize == null
    });
    inputsList.push({
      name: 'large',
      type: 'radio',
      label: this.translateService.instant('font.large'),
      value: 'L',
      class: 'dark',
      checked: currentFontSize === 'L'
    });
    const alert = await this.alertController.create({
      header: this.translateService.instant('selectFontSize'),
      inputs: inputsList,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            this.dbService.setFontSize(data);
          },
          cssClass: 'warning',
        }
      ]
    });
    await alert.present();
  }

  public startSync() {
    if (this.isSync) {
      return;
    }
    if (this.identity.user?.terms == null) {
      this.navController.navigateRoot(['/home', 'terms']);
      return;
    }
    this.insomnia.keepAwake();
    this.isSync = true;
    this.currentPage = 0;
    this.presentAlertUpdate();
  }

  public stopSync() {
    this.isSync = false;
    this.insomnia.allowSleepAgain();
    this.alertController.dismiss();
    this.dbService.initializeDb().finally(() => {
      this.navController.navigateRoot('home');
    });
  }

  public async presentAlertUpdate() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('sync.updating'),
      message: this.translateService.instant('sync.wait'),
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.stopSync();
          }
        }
      ]
    });
    await alert.present().then(() => this.syncContents());
  }

  public hideFooter() {
    this.showFooter = false;
  }

  private syncContents() {
    if (!this.isSync) {
      return;
    }
    this.dbService.sync().then(result => {
      if (result) {
        this.toastService.presentToast('sync.end');
      }
    }).catch(error => {
      this.toastService.presentToast(error.message);
    }).finally(() => {
      this.stopSync();
    });
  }
}
