import { BaseModel } from './base.model';

export class AppFile extends BaseModel {
  name: string;
  path: string;
  absolutepath?: string;
  mime: string;
  md5: string;
  language?: string;
  type?: string;
  thumb?: string;
}
