<div class="container">
  <div class="row mt-2 justify-content-center">
    <div class="col-12 col-md-auto">
      <h3>{{ 'error.notFound' | translate}} (404)</h3>
      <ion-button (click)="goBack()" fill="clear" size="small">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </div>
  </div>
</div>
