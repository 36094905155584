import { BaseModel } from 'src/app/shared/models/base.model';
import { Course } from 'src/app/shared/models/tables/course.model';
import { Node } from 'src/app/shared/models/tables/node.model';
import { Role } from 'src/app/shared/models/tables/role.model';

export class UserCourse extends BaseModel {
  status: string;
  certificationId: string;
  certificationDate: string;
  from: string;
  to: number;
  courseId: number;
  userId: number;
  Course: Course;
  current: Node;
  syncId?: number;

  Roles: Array<Role>;

  constructor(userCourse?: any) {
    super(userCourse);
  }
}
