import { AppFile } from 'src/app/shared/models/app-file.model';
import { BaseModel } from 'src/app/shared/models/base.model';
import { Course } from 'src/app/shared/models/tables/course.model';

export class Certificate extends BaseModel {
  id: number;
  date: string;
  validity: string;
  courseId: number;
  userId: number;
  files: AppFile[];
  course: Course;
  percent?: number; // in base alla scadenza do una percentuale
  syncId?: number;
}
