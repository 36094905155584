import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';
import { AnonymousGuard } from 'src/app/shared/guards/anonymous.guard';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ChapterGuard } from 'src/app/shared/guards/chapter.guard';
import { TermsGuard } from 'src/app/shared/guards/terms.guard';
import { FilePlayerComponent } from 'src/app/shared/helpers/files/file-player.component';
import { FilesListComponent } from 'src/app/shared/helpers/files/files-list.component';
import { FilesUploadComponent } from 'src/app/shared/helpers/files/files-upload.component';
import { TokenInterceptor } from 'src/app/shared/interceptors/token.interceptor';
import { DurationPipe } from 'src/app/shared/pipes/duration.pipe';
import { MinutesToTimePipe } from 'src/app/shared/pipes/minutesToTime.pipe';
import { MultilanguagePipe } from 'src/app/shared/pipes/multilanguage.pipe';
import { RemoveExtensionPipe } from 'src/app/shared/pipes/removeExtension.pipe';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { TermsResolver } from 'src/app/shared/resolvers/terms.resolver';
import { ApiService } from 'src/app/shared/services/api.service';
import { AnswersService } from 'src/app/shared/services/api/answers.service';
import { AppFilesService } from 'src/app/shared/services/api/app-files.service';
import { CertificatesService } from 'src/app/shared/services/api/certificates.service';
import { CoursesService } from 'src/app/shared/services/api/courses.service';
import { TimingService } from 'src/app/shared/services/api/timing.service';
import { UsersService } from 'src/app/shared/services/api/users.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IonicModule,
    AngularFileViewerModule
  ],
  declarations: [
    MultilanguagePipe,
    MinutesToTimePipe,
    DurationPipe,
    SafePipe,
    RemoveExtensionPipe,
    FilesListComponent,
    FilesUploadComponent,
    FilePlayerComponent,
  ],
  entryComponents: [],
  providers: [
    AuthGuard,
    AuthService,
    AppFilesService,
    AnswersService,
    ApiService,
    CertificatesService,
    MultilanguagePipe,
    MinutesToTimePipe,
    ChapterGuard,
    DurationPipe,
    RemoveExtensionPipe,
    SafePipe,
    CoursesService,
    TimingService,
    AnonymousGuard,
    TermsGuard,
    ToastService,
    UsersService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    TermsResolver
  ],
  exports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MultilanguagePipe,
    MinutesToTimePipe,
    DurationPipe,
    RemoveExtensionPipe,
    SafePipe,
    FilesListComponent,
    FilesUploadComponent
  ]
})
export class SharedModule {
  constructor() {
  }
}
