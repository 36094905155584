import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserCourse } from 'src/app/shared/models/tables/userCourse.model';
import { CoursesListConfig } from 'src/app/shared/queryConfigs/courses-list-config.model';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable()
export class CoursesService {
  constructor(
    private apiService: ApiService
  ) {
  }

  query(queryConfig?: CoursesListConfig): Observable<{ userCourses: UserCourse[], count: number }> {
    return this.apiService.get('/usercourses', queryConfig).pipe(
      map(data => ({userCourses: data.userCourses.map(ucourse => new UserCourse(ucourse)), count: data.count}))
    );
  }
}
