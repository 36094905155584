import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {
  transform(sec: number): string {
    const min = Math.floor(sec / 60);
    const remainingSeconds = sec % 60;
    const hours = Math.floor(min / 60);
    const remainingMinutes = min % 60;
    let result = '';
    if (hours > 0) {
      result += hours + 'h';
    }
    if (remainingMinutes > 0) {
      if (hours > 0) {
        result += ' : ';
      }
      result += remainingMinutes + 'm';
    }

    if (hours > 0 || remainingMinutes > 0) {
      result += ' : ';
    }
    result += remainingSeconds + 's';

    return result;
  }
}
