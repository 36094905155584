import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { Term } from 'src/app/shared/models/tables/term.model';
import { ApiService } from 'src/app/shared/services/api.service';
import { JWTService } from 'src/app/shared/services/jwt.service';

/*import { Role } from 'src/app/shared/models/tables/role.model';
import { User } from 'src/app/shared/models/tables/user.model';
*/

interface AuthResponse {
  success: boolean;
  error?: {
    message: string;
  };
  code?: string;
  /* user?: User;
   role?: Role;*/
}

@Injectable()
export class AuthService {
  constructor(
    private apiService: ApiService,
    private jwtService: JWTService
  ) {
  }

  login(username: string, password: string): Observable<boolean> {
    return this.apiService.post('/auth/loginapp', {username, password}).pipe(
      map(data => {
        if (data.success) {
          return this.jwtService.updateTokens({token: data.authToken, refreshToken: data.refreshToken});
        } else if (data.error) {
          throw new Error(data.error.message);
        } else {
          throw new Error('error.unknown');
        }
      })
    );
  }

  refresh(): Observable<boolean> {
    return this.jwtService.tokenRefreshing$.pipe(
      filter(tokenRefreshing => tokenRefreshing === false),
      take(1),
      tap(() => this.jwtService.startTokenRefreshing()),
      switchMap(() => this.jwtService.refreshToken$),
      take(1),
      switchMap(refreshToken => {
        if (refreshToken) {
          return this.apiService.post(`/auth/refresh`, {refreshToken}, false, true);
        } else {
          return null;
        }
      }),
      map(data => {
        if (data && data.success) {
          return this.jwtService.updateTokens({token: data.authToken, refreshToken: data.refreshToken});
        } else if (data.error) {
          throw new Error(data.error.message);
        } else {
          throw new Error('error.unknown');
        }
      }),
      finalize(() => this.jwtService.endTokenRefreshing())
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.apiService.post('/auth/forgotPasswordapp', {email}).pipe(
      map(data => {
        return data;
      })
    );
  }

  checkPasswordResetCode(code: string): Observable<AuthResponse> {
    return this.apiService.post('/auth/checkPasswordResetCode', {code}).pipe(
      map(data => {
        return data;
      })
    );
  }

  cleanTokens() {
    return this.jwtService.removeTokens();
  }

  logout(): Observable<boolean> {
    // @ts-ignore
    return this.jwtService.refreshToken$.pipe(
      take(1),
      switchMap(refreshToken => {
        if (refreshToken) {
          return this.apiService.post(`/auth/logout`, {refreshToken}, false, false);
        } else {
          return null;
        }
      }),
      map(data => {
        return this.jwtService.removeTokens();
      })
    );
  }

  getTerms(): Observable<Term> {
    return this.apiService.get('/terms').pipe(
      map(data => new Term(data.term))
    );
  }

  acceptTerms(): Observable<AuthResponse> {
    return this.apiService.post('/auth/termsapp', {}).pipe(
      map(data => {
        return data;
      })
    );
  }
}
