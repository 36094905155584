<ion-split-pane contentId="main-content">
  <ion-menu [class.d-none]="!showMenu" contentId="main-content" menu-id="main-menu" type="overlay">

    <ion-content>
      <div *ngIf="isLoggedIn" class="user_main_div">
        <div [class.logo_div]="userImg == null" [class.user_div]="userImg != null"
             [style.backgroundImage]="'url('+(userImg ? userImg : logo)+')'" class="bg_image"></div>
        <ion-label *ngIf="isLoggedIn"
                   class="username">{{ identity.user.name | titlecase }} {{ identity.user.surname | titlecase}}</ion-label>
      </div>
      <ion-list *ngIf="isLoggedIn" id="inbox-list">
        <ion-menu-toggle auto-hide="false">
          <ion-item [class.selected]="selectedIndex == '/home'" detail="false"
                    lines="none"
                    routerDirection="root" routerLink="/home">
            <ion-icon ios="home-outline" md="home-sharp" slot="start"></ion-icon>
            <ion-label>{{ 'menu.home' | translate }}</ion-label>
          </ion-item>

          <ion-item [class.selected]="selectedIndex == '/home/certificates'" detail="false"
                    lines="none"
                    routerDirection="root" routerLink="/home/certificates">
            <ion-icon ios="school-outline" md="school-sharp" slot="start"></ion-icon>
            <ion-label>{{ 'menu.certificates' | translate }}</ion-label>
          </ion-item>

          <ion-item [class.selected]="selectedIndex == '/home/profile'" detail="false"
                    lines="none"
                    routerDirection="root" routerLink="/home/profile">
            <ion-icon ios="person-outline" md="person-sharp" slot="start"></ion-icon>
            <ion-label>{{ 'menu.profile' | translate }}</ion-label>
          </ion-item>

          <!--ion-item [class.selected]="selectedIndex == '/analysis-report'" detail="false"
                    lines="none"
                    routerDirection="root" routerLink="/analysis-report">
            <ion-icon ios="bar-chart-outline" md="bar-chart-sharp" slot="start"></ion-icon>
            <ion-label>{{ 'menu.report' | translate }}</ion-label>
          </ion-item-->

          <ion-item (click)="presentAlertLanguages()"
                    detail="false"
                    lines="none" routerDirection="root">
            <ion-icon ios="flag-outline" md="flag-sharp" slot="start"></ion-icon>
            <ion-label>{{ 'menu.language' | translate }}</ion-label>
          </ion-item>

          <ion-item (click)="presentAlertFontSizes()"
                    detail="false"
                    lines="none" routerDirection="root">
            <ion-icon ios="text-outline" md="text-sharp" slot="start"></ion-icon>
            <ion-label>{{ 'menu.font-size' | translate }}</ion-label>
          </ion-item>

          <ion-item (click)="startSync()"
                    detail="false"
                    lines="none" routerDirection="root">
            <ion-icon ios="repeat-outline" md="repeat-sharp" slot="start"></ion-icon>
            <ion-label>{{ 'menu.sync' | translate }}</ion-label>
          </ion-item>

          <!--ion-item (click)="presentAlertReset()"
                    detail="false"
                    lines="none" routerDirection="root">
            <ion-icon ios="skull-outline" md="skull-sharp" slot="start"></ion-icon>
            <ion-label>{{ 'menu.reset' | translate }}</ion-label>
          </ion-item-->

          <ion-item [class.selected]="selectedIndex == '/auth/logout'" detail="false"
                    lines="none"
                    routerDirection="root" routerLink="/auth/logout">
            <ion-icon ios="exit-outline" md="exit-sharp" slot="start"></ion-icon>
            <ion-label>{{ 'menu.logout' | translate }}</ion-label>
          </ion-item>

        </ion-menu-toggle>
      </ion-list>
      <div class="footer">
        <ion-grid class="ion-no-margin ion-no-padding">
          <ion-row>
            <ion-col *ngIf="lastSync">
              <div>
                {{'lastSync' | translate}}:<br/>
                <span
                  class="footer-details">{{ lastSync | date :'short' : 'GMT+' + offset : translateService.getDefaultLang() }}</span>
              </div>
            </ion-col>
            <ion-col>
              <div class="ion-text-right">
                {{'version' | translate}}:<br/>
                <span class="footer-details">{{VersionCode}}</span>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

      </div>
    </ion-content>

  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-split-pane>
<ion-footer *ngIf="showFooter && isLoggedIn">
  <ion-toolbar color="danger">
    <ion-buttons slot="start">
      <ion-button>
        <ion-icon name="alert" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <b class="ion-text-justify">{{'sync-failed' | translate}}</b>
    <ion-buttons slot="end">
      <ion-button (click)="hideFooter()">
        <ion-icon name="close-circle" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
<app-loading></app-loading>
