import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Certificate } from 'src/app/shared/models/tables/certificate.model';
import { CertificatesListConfig } from 'src/app/shared/queryConfigs/certificates-list-config.model';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable()
export class CertificatesService {
  constructor(
    private apiService: ApiService
  ) {
  }

  query(queryConfig?: CertificatesListConfig): Observable<{ certificates: Certificate[], count: number }> {
    return this.apiService.get('/certificates', queryConfig).pipe(
      map(data => ({certificates: data.certificates.map(certificate => new Certificate(certificate)), count: data.count}))
    );
  }
}
