import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'removeExtension'})

export class RemoveExtensionPipe implements PipeTransform {
  transform(item) {
    return item.substring(0, item.indexOf('.'));
  }

}
