import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutComponent } from 'src/app/ui/layout.component';
import { LoadingComponent } from 'src/app/ui/loading.component';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule.forChild(),
    IonicModule,
  ],
  declarations: [
    LayoutComponent,
    LoadingComponent
  ],
  providers: [],
  exports: [
    LayoutComponent
  ]
})
export class UiModule {
}
