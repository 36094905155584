import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'multilanguage',
  pure: false
})
export class MultilanguagePipe implements PipeTransform {
  constructor(public translateService: TranslateService) {

  }

  transform(data: object) {
    if (data == null) {
      return '';
    }
    const lang = this.translateService.currentLang;
    if (data.hasOwnProperty(lang)) {
      return data[lang];
    }
    for (const key of Object.keys(data)) {
      return data[key];
      break;
    }
    return '';
  }
}
