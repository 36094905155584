export class BaseModel {
  createdAt?: Date;
  updatedAt?: Date;

  constructor(object?: any) {
    if (object) {
      for (const propName in object) {
        if (typeof (object[propName]) !== 'undefined') {
          this[propName] = object[propName];
        }
      }
    }
    return this;
  }
}
