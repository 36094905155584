import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/tables/user.model';

import { ApiService } from 'src/app/shared/services/api.service';

@Injectable()
export class UsersService {
  constructor(
    private apiService: ApiService
  ) {
  }

  save(user: User): Observable<{ success: boolean, user?: User, error?: { message: string } }> {
    if (user.id) {
      return this.apiService.put('/users/' + user.id, {user}).pipe(
        map(data => data)
      );
    }
  }
}
