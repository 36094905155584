import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Term } from 'src/app/shared/models/tables/term.model';
import { AuthService } from 'src/app/shared/services/auth.service';


@Injectable()
export class TermsResolver implements Resolve<Term> {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<any> {
    return this.authService.getTerms().pipe(
      map(
        term => term
      ),
      catchError((err) => {
        return of(null);
      })
    );
  }
}
