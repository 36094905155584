import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { DbService } from 'src/app/shared/services/db.service';

interface ChapterSummary {
  id: number,
  numberOfQuestions: number,
  percentRightAnswer: number,
  timeLeft: number;
  percentTimeLeft: number
}

@Injectable()
export class ChapterGuard implements CanActivate {
  chapterSummaries: ChapterSummary[];
  data = null;

  constructor(
    private navController: NavController,
    private dbService: DbService,
    private router: Router
  ) {
  }

  loadChapters(userCourse, chapterId) {
    this.chapterSummaries = [];
    for (const c of userCourse.Course.Chapters) {
      if (c.id === chapterId) {
        break;
      }
      let total = 0;
      let positives = 0;
      for (const n of c.Nodes) {
        total += n.Questions.length;
        positives += n.result ? +n.result.situation : 0;
      }
      const tl = this.dbService.getTimeLeft(c.id);
      let ptl = tl === null ? 0 : (c.time === 0 || c.time == null ? 1 : tl / (c.time * 60));
      if (ptl > 1) {
        ptl = 1;
      }
      const cp: ChapterSummary = {
        id: c.id,
        numberOfQuestions: total,
        percentRightAnswer: total > 0 ? positives / total : 1,
        timeLeft: tl == null ? 0 : tl,
        percentTimeLeft: ptl
      };
      this.chapterSummaries.push(cp);
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let unlocked = true;
    const data = route.queryParams;
    const userCourse = this.dbService.getCourse(data.courseId);
    const chapterId = data.chapterId == null ? +data.id : +data.chapterId;
    this.loadChapters(userCourse, chapterId);
    for (let j = 0; j < this.chapterSummaries.length; j++) {
      unlocked =
        this.chapterSummaries[j].percentTimeLeft >= 1 &&
        this.chapterSummaries[j].percentRightAnswer > userCourse.Course.threshold / 100;
      if (!unlocked) {
        break;
      }
    }
    if (!unlocked) {
      this.navController.navigateRoot('/home').then(() => {
        let navData: NavigationExtras = {
          queryParams: {
            id: data.courseId
          }
        };
        this.navController.navigateForward('/course', navData).then(() => {
          return unlocked;
        });
      });
    } else {
      return unlocked;
    }
  }
}
