import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastService {
  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {
  }

  async presentToast(message, params = null, color = 'dark') {
    if (message == null) {
      return;
    }
    const toast = await this.toastController.create({
      message: this.translateService.instant(message, params),
      duration: 3000,
      color
    });
    toast.present();
  }
}
