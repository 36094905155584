import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { DbService } from 'src/app/shared/services/db.service';
import { JWTService } from 'src/app/shared/services/jwt.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public platformReady = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService,
    private jwtService: JWTService,
    private router: Router,
    private storage: Storage,
    private dbService: DbService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.storage.create().then(() => {
        const url = this.router.url;
        registerLocaleData(localeIt);
        registerLocaleData(localeDe);
        registerLocaleData(localeEn);
        this.setInitialLanguage();
        this.translateService.onLangChange.subscribe(() => {
          this.storage.set('language', this.translateService.currentLang);
        });
        if (this.jwtService.getStatus()) { // sul live mi sta dando problemi
          this.dbService.dbReady$.pipe(take(1)).subscribe(() => {
            this.platformReady = true;
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.router.navigateByUrl(url);
          });
        } else {
          this.jwtService.ready.pipe(take(1)).subscribe(() => {
            this.dbService.dbReady$.pipe(take(1)).subscribe(() => {
              this.platformReady = true;
              this.statusBar.styleDefault();
              this.splashScreen.hide();
              this.router.navigateByUrl(url);
            });
          });
        }
      });

    });
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // console.log('------->', event.url);
      }
    });
  }

  private setInitialLanguage() {
    let lang = null;
    // verifico se è stato settato
    this.storage.get('language').then((val) => {
        if (environment.languages.find(l => l.code === val)) {
          lang = val;
        }
      }
    ).finally(() => {
      if (lang == null) {
        // uso quello del device
        const navigatorLanguage = this.translateService.getBrowserLang();
        const val = navigatorLanguage.split('-')[0].toLowerCase();
        if (val === null || !environment.languages.find(l => l.code === val)) {
          // uso il primo di environment.languages o de
          if (environment.languages.length > 0) {
            lang = environment.languages[0].code;
          } else {
            lang = 'de';
          }
        } else {
          lang = environment.languages.find(l => l.code === val).code;
        }
      }
      this.translateService.use(lang);
    });
  }
}
