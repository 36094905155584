import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ListConfig } from 'src/app/shared/models/queryConfigs/list-config.model';
import { JWTService } from 'src/app/shared/services/jwt.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private jwtService: JWTService,
    private loadingService: LoadingService,
    public toastService: ToastService,
  ) {
    this.setHeaders();
  }

  get(path: string, queryConfig: ListConfig = null, showLoading = true,
      params: HttpParams = new HttpParams(), responseType: any = 'json'): Observable<any> {
    if (queryConfig) {
      Object.keys(queryConfig.filters)
        .forEach((key) => {
          if (queryConfig.filters[key] !== null) {
            params = params.set(key, queryConfig.filters[key]);
          }
        });

      Object.keys(queryConfig.sorting)
        .forEach((key) => {
          if (queryConfig.sorting[key] !== null) {
            params = params.set(key, queryConfig.sorting[key]);
          }
        });

      Object.keys(queryConfig.limits)
        .forEach((key) => {
          if (queryConfig.limits[key] !== null) {
            params = params.set(key, queryConfig.limits[key]);
          }
        });

      Object.keys(queryConfig.include)
        .forEach((key) => {
          if (queryConfig.include[key]) {
            params = params.set('include' + key.charAt(0).toUpperCase() + key.slice(1), queryConfig.include[key]);
          }
        });
    }
    return this.jwtService.tokenRefreshing$.pipe(
      filter(tokenRefreshing => tokenRefreshing === false),
      take(1),
      tap(() => {
        if (showLoading) {
          this.loadingService.start();
        }
      }),
      switchMap(() => this.http.get(`${environment.api_url}${path}`, {
        headers: this.headers,
        params,
        responseType,
        observe: 'response'
      })),
      catchError(error => this.handleError(error)),
      finalize(() => {
        if (showLoading) {
          this.loadingService.end();
        }
      }),
      map(response => response.body)
    );
  }

  post(path: string, body: object = {}, showLoading = true, forceTokenRefreshing = false): Observable<any> {
    return this.jwtService.tokenRefreshing$.pipe(
      filter(tokenRefreshing => forceTokenRefreshing || tokenRefreshing === false),
      take(1),
      tap(() => {
        if (showLoading) {
          this.loadingService.start();
        }
      }),
      switchMap(() => this.http.post(`${environment.api_url}${path}`, JSON.stringify(body), {headers: this.headers, observe: 'response'})),
      catchError(error => this.handleError(error)),
      finalize(() => {
        if (showLoading) {
          this.loadingService.end();
        }
      }),
      map(response => response.body)
    );
  }

  put(path: string, body: object = {}, showLoading = true): Observable<any> {
    return this.jwtService.tokenRefreshing$.pipe(
      filter(tokenRefreshing => tokenRefreshing === false),
      take(1),
      tap(() => {
        if (showLoading) {
          this.loadingService.start();
        }
      }),
      switchMap(() => this.http.put(`${environment.api_url}${path}`, JSON.stringify(body), {headers: this.headers, observe: 'response'})),
      catchError(error => this.handleError(error)),
      finalize(() => {
        if (showLoading) {
          this.loadingService.end();
        }
      }),
      map(response => response.body)
    );
  }

  download(path: string): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`, {responseType: 'blob'});
  }

  delete(path: string, showLoading = true): Observable<any> {
    return this.jwtService.tokenRefreshing$.pipe(
      filter(tokenRefreshing => tokenRefreshing === false),
      take(1),
      tap(() => {
        if (showLoading) {
          this.loadingService.start();
        }
      }),
      switchMap(() => this.http.delete(`${environment.api_url}${path}`, {headers: this.headers, observe: 'response'})),
      catchError(error => this.handleError(error)),
      finalize(() => {
        if (showLoading) {
          this.loadingService.end();
        }
      }),
      map(response => response.body)
    );
  }

  // tslint:disable-next-line:max-line-length
  upload(path: string, files: { [key: string]: File }, params: { [key: string]: string }): Observable<{ success: boolean, progress: number } | any> {
    let formDataHeaders = new HttpHeaders();
    for (const headerKey of this.headers.keys()) {
      if (headerKey !== 'Content-Type') {
        formDataHeaders = formDataHeaders.append(headerKey, this.headers.get(headerKey));
      }
    }

    const formData = new FormData();
    for (const fileKey of Object.keys(files)) {
      if (files[fileKey]) {
        formData.append(fileKey, files[fileKey]);
      }
    }

    for (const paramKey of Object.keys(params)) {
      if (params[paramKey]) {
        formData.append(paramKey, params[paramKey]);
      }
    }

    const req = new HttpRequest('POST', `${environment.api_url}${path}`, formData, {headers: formDataHeaders, reportProgress: true});
    return this.http.request(req).pipe(
      catchError(error => this.handleError(error)),
      map((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return {success: null, progress: 0};
          case HttpEventType.UploadProgress:
            return {success: null, progress: Math.round(100 * event.loaded / event.total)};
          case HttpEventType.Response:
            return event.body;
        }
      })
    );
  }

  private setHeaders() {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    this.headers = new HttpHeaders(headersConfig);
  }

  private handleError(error: any) {
    let _e;
    if (error.status === 404) {
      _e = {status: 404, message: 'error.notFound'};
    } else if (error.error && error.error.message) {
      _e = error.error;
    } else if (error && error.message) {
      _e = error;
    } else {
      _e = {message: 'error.unexpected', response: error};
    }
    this.toastService.presentToast(_e.message);
    return throwError(_e);
  }
}
