export const environment = {
  version: '0.5.9',
    production: true,
    title: 'training',
    api_url: 'https://admin.demonte-partner-training.com/api',
    logoPath: 'assets/logo.png',
    headerPath: 'assets/header.png',
    syncTimingTimeInterval: 30000,
    languages: [
        {code: 'de', label: 'Deutsch', flag: 'de'},
        {code: 'it', label: 'Italiano', flag: 'it'},
        // {code: 'en', label: 'English', flag: 'gb'}
    ],
};
