import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable()
export class TimingService {
  constructor(
    private apiService: ApiService
  ) {
  }

  query(): Observable<{ success: boolean, timing: string, lastSync: number }> {
    return this.apiService.get('/timing', null, false).pipe(
      map(data => data)
    );
  }

  save(timing): Observable<{ success: boolean, lastSync: number, error?: { message: string } }> {
    return this.apiService.put('/timing', timing, false).pipe(
      map(data => data)
    );
  }
}
