import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { JWTService } from 'src/app/shared/services/jwt.service';

@Injectable()
export class TermsGuard implements CanActivate {
  constructor(
    private navController: NavController,
    private jwtService: JWTService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.jwtService.identity$.pipe(
      take(1),
      map(identity => {
        if (identity?.user?.terms == null) {
          this.navController.navigateRoot(['/home', 'terms']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
