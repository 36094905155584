import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Certificate } from 'src/app/shared/models/tables/certificate.model';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable()
export class AnswersService {
  constructor(
    private apiService: ApiService
  ) {
  }

  save(answers): Observable<{ success: boolean, nodeId: number, situation: number, increment: number, certificate?: Certificate, error?: { message: string } }> {
    return this.apiService.post('/answers/', answers).pipe(
      map(data => data)
    );
  }
}
