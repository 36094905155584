import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { Observable } from 'rxjs';
import { catchError, map, retryWhen, switchMap, take } from 'rxjs/operators';

import { AuthService } from 'src/app/shared/services/auth.service';
import { JWTService } from 'src/app/shared/services/jwt.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private jwtService: JWTService,
    private navController: NavController
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.jwtService.token$.pipe(
      take(1),
      map(token => token ? request.clone({setHeaders: {Authorization: 'Bearer ' + token}}) : request),
      switchMap(authenticatedRequest => next.handle(authenticatedRequest)),
      retryWhen(errors =>
        errors.pipe(
          switchMap(error => {
            if (error instanceof HttpErrorResponse) {
              switch (error.status) {
                case 401:
                  return this.authService.refresh().pipe(
                    map(() => error),
                    catchError(() => this.authService.logout().pipe(
                      map(() => {
                        this.navController.navigateRoot(['/auth/login']);
                        throw new Error('error.impossibleToRenewAccount');
                      })
                    ))
                  );
                default:
                  throw error;
              }
            } else {
              throw error;
            }
          })
        )
      )
    );
  }
}
