import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/shared/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  opaque: boolean;
  visible: boolean;
  timeout = 0.2;
  startTimer: any;
  stopTimer: any;


  constructor(
    private loadingService: LoadingService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.loadingService.loading.subscribe(loading => {
      if (loading) {
        if (this.stopTimer) {
          clearTimeout(this.stopTimer);
        }
        this.visible = true;
        this.cd.detectChanges();
        this.startTimer = setTimeout(() => {
          this.opaque = true;
        }, 40);
      } else if (!loading) {
        if (this.startTimer) {
          clearTimeout(this.startTimer);
        }
        this.opaque = false;
        this.cd.detectChanges();
        this.stopTimer = setTimeout(() => {
          this.visible = false;
        }, this.timeout * 1000);
      }
    });
  }
}
