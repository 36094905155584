import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject: BehaviorSubject<boolean>;

  constructor() {
    this.loadingSubject = new BehaviorSubject<boolean>(false);
  }

  get loading() {
    return this.loadingSubject.asObservable();
  }

  start() {
    this.loadingSubject.next(true);
  }

  end() {
    this.loadingSubject.next(false);
  }
}
