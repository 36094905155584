import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuSubject: BehaviorSubject<boolean>;
  private syncSubject: BehaviorSubject<number>;

  constructor() {
    this.menuSubject = new BehaviorSubject<boolean>(true);
    this.syncSubject = new BehaviorSubject<number>(0);
  }

  get menu() {
    return this.menuSubject.asObservable();
  }

  show() {
    this.menuSubject.next(true);
  }

  hide() {
    this.menuSubject.next(false);
  }

  get sync() {
    return this.syncSubject.asObservable();
  }

  startSync() {
    this.syncSubject.next(Date.now());
  }
}
