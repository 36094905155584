import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'minutesToTime'})
export class MinutesToTimePipe implements PipeTransform {

  constructor() {
  }

  transform(min: number): string {
    const hours = Math.floor(min / 60);
    const remainingMinutes = min % 60;
    const result = hours > 0 ? hours + 'h : ' + remainingMinutes + 'm' : remainingMinutes + 'm';
    return result;
  }
}
