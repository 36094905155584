import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  templateUrl: 'page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})

export class PageNotFoundComponent {

  constructor(
    private location: Location,
  ) {
  }

  goBack(): void {
    this.location.back();
  }
}
