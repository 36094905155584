import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { JWTService } from 'src/app/shared/services/jwt.service';

@Injectable()
export class AnonymousGuard implements CanActivate {
  constructor(
    private router: Router,
    private jwtService: JWTService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.jwtService.token$.pipe(
      take(1),
      map(token => {
        if (token == null) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
